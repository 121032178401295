import React from 'react';
import { PortableText } from '@portabletext/react';
import imageUrlBuilder from '@sanity/image-url';

const usePortableText = () => {
  const urlFor = (source: any) => {
    return imageUrlBuilder({ projectId: 'lphao58h', dataset: 'production' }).image(source);
  };

  const components = {
    types: {
      imageBlock: ({ value }: any) => {
        return (
          <div className="imgWrapper">
            <img
              loading="lazy"
              src={urlFor(value.image.asset._ref).width(600).url()}
              alt={value.alt}
              className="articleImg"
            />
            <p className="captionImg">{value.caption}</p>
          </div>
        );
      },
    },
    marks: {
      em: ({ children }: any) => <em className="textItalic">{children}</em>,
      link: ({ value, children }: any) => {
        const target = (value?.href || '').startsWith('http') ? '_blank' : undefined;

        return (
          <a href={value?.href} target={target} rel={'_blank' || 'noindex nofollow'} className="link">
            {children}
          </a>
        );
      },
    },
    block: {
      h1: ({ children }: any) => <h1 className="heading h1">{children}</h1>,
      h2: (data: any) => {
        const { children } = data;

        return (
          <h2 id={children} className="heading h2">
            {children}
          </h2>
        );
      },
      h3: (data: any) => {
        const { children } = data;

        return (
          <h3 id={children} className="heading h3">
            {children}
          </h3>
        );
      },
      h4: ({ children }: any) => <h4 className="heading h4">{children}</h4>,
      h5: ({ children }: any) => <h5 className="heading h5">{children}</h5>,
      h6: ({ children }: any) => <h6 className="heading h6">{children}</h6>,
      normal: ({ children }: any) => <p className="paragraph">{children}</p>,
      blockquote: ({ children }: any) => {
        return (
          <figure>
            <blockquote className="blockquote">
              <span>“</span>
              <p>{children}</p>
            </blockquote>
            <figcaption className="figcaption">Aldous Huxley</figcaption>
          </figure>
        );
      },
    },
    list: {
      bullet: ({ children }: any) => <ul className="list">{children}</ul>,
      number: ({ children }: any) => <ol className="numbered">{children}</ol>,
    },
    listItem: {
      bullet: ({ children }: any) => <li className="listItem">{children}</li>,
      number: ({ children }: any) => <li className="listItem">{children}</li>,
    },
  };
  const renderPortableText = (data?: any) => {
    return <PortableText value={data} components={components} onMissingComponent={false} />;
  };

  return {
    renderPortableText,
    components,
  };
};

export default usePortableText;
